@import "../../../Constants/color.scss";
.RouteRevenuePlanning {
  display: flex;
  margin: 10px 10px 0px 5px;
  h2 {
    text-transform: uppercase;
  }  
  .graphs {
    margin: 0px 10px 10px 10px;
    width: 63%;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
  }
  .graph {
    width: 100%;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    flex: 3;
    .x_panel {
      width: 49.5%;
    }
  }
  .cards-main :nth-child(1) {
    margin-left: 0px !important;
  }
  .cards-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
    .cards {
      width: 33%;
      height: 10h;
      border-radius: 5px;
      padding: 0.7vw;
      margin-left: 12px;
      background: #2e303f;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19);
      .card-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        .count_top {
          font-size: 1vw;
        }
        .count {
          font-size: 1.3vw;
          font-weight: 600;
          color: #ff7139;
        }
      }
      .count_bottom {
        display: flex;
        justify-content: space-between;
        #revenue_YTD_per {
          font-size: 0.8vw;
          font-style: inherit;
        }
      }
    }
  }
  .tables {
    width: 27%;
    .x_panel {
      display: flex;
      flex-direction: column;
      padding: 0px;
      width: 95%;
      margin-left: 10px;
      height: calc(100vh - 70px);
      .table-flex {
        flex: 1;
        padding: 8px;
      }
    }
    .x_content {
      margin-top: 0px;
    }
  }
  .channel {
    width: 100%;
  }
  .anciallary {
    width: 100%;
  }
  .label {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: unset;
  }
  .select {
    color: white;
    background-color: transparent;
    padding-top: 6px;
    padding-bottom: 6px;
    border: none;
    option {
      background-color: $darkPrimary;
    }
  }
  .header-dropdown {
    color: #1ee9d1 !important;
    font-size: 1vw;
    padding-left: 0px;
    margin-bottom: 2px;
  }
  p {
    color: red;
    font-size: 12px;
  }
  img {
    width: 18px;
  }
  .btn-primary {
    width: 150px;
  }
  .bar {
    /* fill: rgb(105, 194, 66); */
    /* fill:rgb(238, 210, 2); */
    fill: rgb(12, 224, 212);
    opacity: 0.7;
  }

  .bar:hover {
    opacity: 0.4;
  }

  .axis {
    font: 10px sans-serif;
  }

  .axis path {
    fill: none;
    stroke: #adb2b5;
    shape-rendering: crispEdges;
  }
  .y line {
    fill: none;
    stroke: #adb2b5;
    shape-rendering: crispEdges;
  }

  .x line {
    fill: none;
    stroke: none;
    shape-rendering: crispEdges;
  }

  .d3-tip {
    line-height: 1;
    font-weight: bold;
    padding: 12px;
    background: #2a3f54;
    color: #fff;
    border-radius: 2px;
  }

  /* Creates a small triangle extender for the tooltip */
  .d3-tip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    content: "\25BC";
    position: absolute;
    text-align: center;
  }

  /* Style northward tooltips differently */
  .d3-tip.n:after {
    margin: -1px 0 0 0;
    top: 100%;
    left: 0;
  }

  text {
    fill: whitesmoke;
    font-size: 12px;
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .revenuetooltip {
    padding: 0px !important;
  }
  .tooltip {
    padding: 0px !important;
  }

  .padding {
    padding: 15px !important;
    color: white !important;
  }

  .country-name {
    margin: 0 !important;
  }
  .queens {
    background: rgb(100, 187, 227);
  }
  .kings {
    background: rgb(238, 210, 2);
  }
  .bronx {
    background: rgb(105, 194, 66);
  }

  .key-dot {
    display: inline-block;
    height: 10px;
    margin-right: 0.5em;
    width: 10px;
    font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
  }
  .legend {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5%;
  }
  #legend2 {
    overflow: hidden;
    padding-left: 30%;
  }
  .legend2 {
    float: left;
    padding-right: 6%;
  }
  .modal-content {
    background-color: white !important;
    color: #2a3f54e8;
  }
  .modal-header .close {
    color: #2a3f54e8;
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .RouteDashboard {
    flex-direction: column;
    .graphs {
      width: 100%;
      margin-left: 0px;
    }
    .graphs .graph {
      width: 100% !important;
    }
    .tables {
      width: 100%;
      margin-left: 0px;
    }
  }
}

@media (max-height: 620px) {
  .RouteDashboard {
    .header-dropdown {
      font-size: 13px !important;
    }
    .reduce-margin {
      margin-bottom: 0px;
    }
  }
}
