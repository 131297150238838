$primaryColor: rgba(42, 63, 84, 0.9098039215686274);
$secondaryColor: #2c3f4c;
$lightPrimary: #2c3f4cbd;
$white: #fff;
$black: #000;
$green: #10da10;
$darkerGreen: #2c9c2c;
$redText: #f85948;
$red: #f83520;
$orange: #ff7139;
$darkOrange: linear-gradient(to right, #f8543a, #ff7139, #ff7139);
$lightGray: #ffffff2b;
$dark_grey: #7f8c8d;
$gray: #ededed;
$lightBlue: #b7e4ff;
$header: #2a3f54;
$darkPrimary: #2e303f;
$darkSencondary: #020202;
$blue: #1784c7;
$darkBlue: #004480;
$darkpink: #dc004e;
$lightGreen: #1ee9d1;
$darkGreen: #009688;
$lightPurple: #c59ae6;
$lightRed: #f69999;
$grayBorderColor: rgba(255, 255, 255, 0.16862745098039217);
