@import "../../Constants/color.scss";

.invalidLogin {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $darkPrimary;
    .invalid-btn {
        width: 250px;
        height: 55px;
        background: -webkit-linear-gradient(right, #27548e, #3d70ab, #27548e);
        border: none;
        font-size: 20px;
        border-radius: 10px;
        margin-top: 15px;
        text-transform: none;
        img{
            width: 26px;
        }
    }
}
