@import "../../Constants/color.scss";

.page-not-found {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $darkSencondary;
}
