@import "../../Constants/color";

.edit-user-modules {
  margin-right: 5px;
  width: 24%;
  padding: 10px;
  margin-bottom: 10px;
  border: solid 0.5px $grayBorderColor;
}

.resetpass-body{
  padding-bottom: 10px;
  .form-control{
    border: solid 1px rgba(83, 57, 57, 0.23) !important;
    margin-bottom: 10px;
  }
}

.user-module-search {
  padding: 20px;
  background-color: $darkPrimary;
  margin: 10px;
  height: calc(100vh - 72px);
  .add {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $dark_grey;
    h2 {
      color: $lightGreen !important;
      font-size: 24px !important;
      font-weight: 400;
    }
    .btn {
      background: $darkOrange;
      width: 150px;
      outline: none;
      border: none;
      height: 35px;
    }
  }
  .module-form {
    display: flex;
    align-items: flex-end;
    .form-group {
      width: 25%;
      margin-right: 30px;
    }
    label {
      color: $white;
      font-size: 14px;
    }
    input {
      background-color: $darkPrimary;
      color: $white;
      margin-top: 10px;
    }
    .btn {
      margin-bottom: 9px;
      background: $darkOrange;
      width: 150px;
      outline: none;
      border: none;
      height: 35px;
    }
  }
  .user-module-table {
    margin-top: 50px;
    h4 {
      color: $white !important;
      margin: 20px 0px !important;
      font-size: 20px !important;
    }
    .fa {
      font-size: 14px;
      cursor: pointer;
    }
    .fa-trash {
      margin-left: 15px;
    }
    .ag-overlay-no-rows-center {
      margin-top: 20px !important;
    }
  }
}

.user-module {
  padding: 20px;
  background-color: $darkPrimary;
  margin: 10px;
  height: calc(100vh - 72px);
  overflow: auto;
  .add {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $dark_grey;
    h2 {
      color: $lightGreen !important;
      font-size: 24px !important;
      font-weight: 400;
    }
    .reset {
      width: 180px;
      background: $darkOrange;
      outline: none;
      border: none;
      height: 35px;
    }
  }
  .module-form {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    .profile {
      width: 100% !important;
      display: flex;
      .sysModules-main {
        display: flex;
        flex-wrap: wrap;
        .sysModules {
          padding: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          border: 0.5px solid $grayBorderColor;
          width: auto;
        }
      }
    }
    .form-group {
      width: 30%;
      margin-right: 30px;
    }
    .system-module-list {
      margin: 10px 0px;
      width: 100% !important;
      border-top: 1px solid #7f8c8d;
      padding: 15px 0px 0px 0px !important;
    }
    .access {
      display: flex;
      align-items: center;
      margin-top: 10px;
      width: 100% !important;
      border-top: 1px solid #7f8c8d;
      padding-top: 10px;
      margin: 10px 0px;
    }
    .dropdowns {
      display: flex;
      width: 100%;
      padding-bottom: 10px;
    }
    label {
      color: $white;
      font-size: 14px;
      margin-right: 10px;
    }
    input {
      background-color: $darkPrimary;
      color: $white;
      margin-top: 10px;
    }
    input[type="radio"] {
      cursor: pointer;
    }
    input[type="checkbox"] {
      cursor: pointer;
    }
    select {
      background-color: $darkPrimary;
      color: $white;
      margin-top: 10px;
      cursor: pointer;
    }
    .details {
      font-size: 14px;
      display: contents;
    }
  }
  p {
    font-size: 14px;
    color: #f23636c7;
    margin: 0px;
  }
  .action-btn {
    display: flex;
    justify-content: center;
    .btn {
      margin-top: 20px;
      background: $darkOrange;
      width: 150px;
      outline: none;
      border: none;
      height: 35px;
    }
    .btn:hover,
    .btn:focus {
      color: $white;
    }
  }
}
