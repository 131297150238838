@import "../../../Constants/color.scss";

.AgentDashboard {
  display: flex;
  margin: 10px;
  .x_title{
    align-items: center;
    height: 40px;
  }
  h2 {
    text-transform: uppercase;
  }
  .total-RO {
    color: $orange;
  }
  .x_panel {
    height: calc(100vh - 72px);
    .agents-tables {
      overflow: auto;
      height: calc(100vh - 20vh - 225px);
    }
    .currency-selection {
      display: flex;
      position: absolute;
      right: 0px;
    }
  }
  .row {
    width: 100vw;
  }
  .channel {
    width: 100%;
  }
  .anciallary {
    width: 100%;
  }
  .label {
    margin-right: 10px;
    font-size: 14px;
    font-weight: unset;
  }
  .select {
    color: white;
    background-color: #2e303f;
    padding: 8px;
  }
  p {
    color: red;
    font-size: 12px;
  }
  img {
    width: 18px;
  }
  .btn-primary {
    width: 150px;
  }
  .bar {
    /* fill: rgb(105, 194, 66); */
    /* fill:rgb(238, 210, 2); */
    fill: rgb(12, 224, 212);
    opacity: 0.7;
  }

  .bar:hover {
    opacity: 0.4;
  }

  .axis {
    font: 10px sans-serif;
  }

  .axis path {
    fill: none;
    stroke: #adb2b5;
    shape-rendering: crispEdges;
  }
  .y line {
    fill: none;
    stroke: #adb2b5;
    shape-rendering: crispEdges;
  }

  .x line {
    fill: none;
    stroke: none;
    shape-rendering: crispEdges;
  }

  .d3-tip {
    line-height: 1;
    font-weight: bold;
    padding: 12px;
    background: #2a3f54;
    color: #fff;
    border-radius: 2px;
  }

  /* Creates a small triangle extender for the tooltip */
  .d3-tip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    content: "\25BC";
    position: absolute;
    text-align: center;
  }

  /* Style northward tooltips differently */
  .d3-tip.n:after {
    margin: -1px 0 0 0;
    top: 100%;
    left: 0;
  }

  text {
    fill: whitesmoke;
  }

  .revenuetooltip {
    padding: 0px !important;
  }
  .tooltip {
    padding: 0px !important;
  }

  .padding {
    padding: 15px !important;
    color: white !important;
  }
  .loader {
    margin-left: 130px;
  }

  .country-name {
    margin: 0 !important;
  }
  .queens {
    background: rgb(100, 187, 227);
  }
  .kings {
    background: rgb(238, 210, 2);
  }
  .bronx {
    background: rgb(105, 194, 66);
  }

  .key-dot {
    display: inline-block;
    height: 10px;
    margin-right: 0.5em;
    width: 10px;
    font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
  }
  .legend {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5%;
  }
  #legend2 {
    overflow: hidden;
    padding-left: 30%;
  }
  .legend2 {
    float: left;
    padding-right: 6%;
  }
  .modal-content {
    background-color: white !important;
    color: #2a3f54e8;
  }
  .modal-header .close {
    color: #2a3f54e8;
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .AgentDashboard {
    flex-direction: column;
    .graphs {
      width: 100%;
      margin-left: 0px;
    }
    .graphs .graph {
      width: 100% !important;
    }
    .tables {
      width: 100%;
      margin-left: 0px;
    }
  }
}
