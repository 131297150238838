@import "../../Constants/color";
/* -------------------------------- 

Primary style

-------------------------------- */
.top-market {
  .top-market-main {
    background-color: $darkPrimary;
    margin-top: 10px;
    height: calc(100vh - 75px);
    padding: 5px 10px 0px 10px;
  }
  .navdesign {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    margin-top: 0px;
  }
  .toggle-agent-competitor {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .switch {
    margin-top: -10px;
    margin-right: -25px;
  }

  section h2 {
    width: 100%;
    color: $secondaryColor;
    font-size: 24px !important;
    border-bottom: 1px solid white;
    padding-bottom: 10px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 20px;
  }
  .table-name {
    margin-top: 0px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
  }

  .form-group {
    display: flex;
    align-items: center;
    margin: 10px 15px;
    max-width: 160px;
  }

  h4 {
    margin-right: 10px;
    font-size: 1vw;
  }

  .swap {
    height: 30px;
    margin: -10px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .fa-exchange {
      color: $white;
      font-size: 16px;
    }
  }

  .transition {
    -moz-transition: all 0.5s linear;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
  }
  .rotate {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

