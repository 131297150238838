.hide-watermark{
      background-color: #262a33;
      width: 100px;
      height: 18px;
      position: absolute;
      margin-top: -18px;
    }
.hide-watermarkWhiite{
      background-color: rgb(230, 230, 230);
      width: 100px;
      height: 20px;
      position: absolute;
      margin-top: -22px;
      margin-right: -6px;
    }
