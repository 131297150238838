@import "../../Constants/color";

.targetRational {
  padding: 20px;
  background-color: $darkPrimary;
  margin: 10px;
  height: calc(100vh - 72px);

  .add {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $dark_grey;
    margin-bottom: 10px;
    h2 {
      color: $lightGreen !important;
      font-size: 24px !important;
      margin-top: 0px;
    }
    .MuiFormGroup-root {
      flex-direction: row;
      align-items: center;
      .MuiFormControlLabel-root {
        margin: 10px;
      }
      .MuiSwitch-colorSecondary.Mui-checked {
        color: $orange;
      }
      .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: #f0862a;
      }
    }
  }
  .targetRational-form {
    display: flex;
    justify-content: space-between;
    overflow: auto;
    width: calc(100vw - 60px);
    height: calc(100vh - 180px);
    .section1 {
      width: 19%;
      margin: auto;
      .network {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .rounded-box {
          width: 80%;
          overflow: auto;
          border-radius: 15px;
          background-color: #262c2e;
          border: 0.5px solid $grayBorderColor;
          .top-rb {
            width: 100%;
            height: 40px;
            background: $blue;
            p {
              text-align: center;
              padding: 10px;
              font-size: 15px;
              font-weight: 600;
              margin: 0px;
            }
          }
          .content {
            padding: 10px;
            display: flex;
            align-items: baseline;
            justify-content: space-around;
          }
        }
      }
    }
    .section2 {
      width: 25%;
      margin: auto;
      .rev-impact-combination {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .rounded-box-main {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 15px;
          background-color: #2d3436;
          border: 0.5px solid $grayBorderColor;
          padding-bottom: 15px;
          .inner-rounded-box {
            width: 90%;
            overflow: auto;
            border-radius: 15px;
            background-color: #262c2e;
            border: 0.5px solid $grayBorderColor;
            margin-top: 15px;
          }
          .top-rb {
            width: 100%;
            background: $blue;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            p {
              text-align: center;
              padding: 10px;
              font-size: 15px;
              font-weight: 600;
              margin: 0px;
            }
          }
          .content {
            padding: 10px;
            display: flex;
            align-items: baseline;
            flex-direction: column;
            justify-content: space-between;
          }
          .sub-content {
            width: 100%;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
          }
          .key {
            width: 100%;
            margin: 1% 2% 2% 0px;
          }
          .value {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            width: 200px;
          }
        }
      }
    }
    .section3 {
      width: 50%;
      margin: auto;
      .main-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
      }
      .rounded-box-main {
        width: 90%;
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        background-color: #2d3436;
        border: 0.5px solid $grayBorderColor;
        padding-bottom: 15px;
        .inner-rounded-box {
          width: 45%;
          overflow: auto;
          border-radius: 15px;
          background-color: #262c2e;
          border: 0.5px solid $grayBorderColor;
          margin: 15px 10px 30px 20px;
        }
        .top-rb {
          width: 100%;
          background: $blue;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          p {
            text-align: center;
            padding: 10px;
            font-size: 15px;
            font-weight: 600;
            margin: 0px;
          }
        }
        .content {
          padding: 15px;
          display: flex;
          align-items: baseline;
          flex-direction: column;
          justify-content: space-between;
        }
        .sub-content {
          width: 100%;
          display: flex;
          align-items: baseline;
          justify-content: space-between;
        }
        .key {
          width: 100%;
          margin: 1% 2% 2% 0px;
        }
        .value {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          width: 250px;
        }
        .remove-margin {
          margin: 0px 20px 0px 0px;
        }
      }
      .piechart-main {
        position: relative;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: -15px;
        .piechart {
          position: absolute;
        }
      }
    }

    .triangle-right {
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-left: 20px solid $white;
      border-bottom: 10px solid transparent;
    }
  }

  .alerts-setting-form {
    display: flex;
    flex-wrap: wrap;
  }
}
