@import "../../Constants/color";

.ddschart {
  padding: 20px;
  background-color: $darkPrimary;
  margin: 10px;
  height: calc(100vh - 72px);

  .add {
    margin-bottom: 30px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $dark_grey;
    h2 {
      color: $lightGreen !important;
      font-size: 24px !important;
    }
    .MuiFormGroup-root {
      flex-direction: row;
      align-items: center;
      .MuiFormControlLabel-root {
        margin: 10px;
      }
      .MuiSwitch-colorSecondary.Mui-checked {
        color: $orange;
      }
      .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: #f0862a;
      }
    }
  }
  .ddschart-form {
    align-items: center;
    justify-content: center;
    overflow: auto;
    width: calc(100vw - 60px);
    height: calc(100vh - 180px);
    position: relative;
    .hide-watermark{
      background-color: #262a33;
      width: 100px;
      height: 20px;
      position: absolute;
      margin-top: -20px;
    }
  }

  .alerts-setting-form {
    display: flex;
    flex-wrap: wrap;
  }
}
