@import "../../Constants/color";

.system-module-search {
  padding: 20px;
  background-color: $darkPrimary;
  margin: 10px;
  height: calc(100vh - 72px);
  .add {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $dark_grey;
    h2 {
      color: $lightGreen !important;
      font-size: 24px !important;
      font-weight: 400;
    }
    .btn {
      background: $darkOrange;
      width: 150px;
      outline: none;
      border: none;
      height: 35px;
    }
  }
  .module-form {
    display: flex;
    align-items: flex-end;
    .form-group {
      margin-right: 30px;
      width: 25%;
    }
    label {
      color: $white;
      font-size: 14px;
    }
    input {
      background-color: $darkPrimary;
      color: $white;
      margin-top: 10px;
    }
    .btn {
      margin-bottom: 10px;
      background: $darkOrange;
      width: 150px;
      outline: none;
      border: none;
      height: 35px;
    }
  }
  .system-module-table {
    margin-top: 50px;
    h4 {
      color: $white !important;
      margin: 20px 0px !important;
      font-size: 20px !important;
    }
    .fa {
      font-size: 14px;
      cursor: pointer;
    }
    .fa-trash {
      margin-left: 15px;
    }
    .ag-overlay-no-rows-center {
      margin-top: 20px !important;
    }
  }
}

.system-module {
  padding: 20px;
  background-color: $darkPrimary;
  margin: 10px;
  height: calc(100vh - 72px);
  .add {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $dark_grey;
    h2 {
      color: $lightGreen !important;
      font-size: 24px !important;
      font-weight: 400;
    }
  }
  .module-form {
    display: flex;
    align-items: flex-end;
    .form-group {
      width: 25%;
      margin-right: 30px;
    }
    label {
      color: $white;
      font-size: 14px;
      .hint {
        opacity: 0.6;
        font-weight: 400;;
      }
      display: block;;
    }
    input {
      background-color: $darkPrimary;
      color: $white;
      margin-top: 10px;
    }
    select option {
      margin: 40px;
      background: $darkPrimary;
      color: #fff;
    }
    select {
      width: 100%;
      background: transparent;
      font-size: 14px;
      padding: 8px 8px;
      margin-top: 10px;
    }
    p {
      position: absolute;
      font-size: 12px;
      margin-top: 6px;
      color: #f23636c7;
    }
    .action-btn {
      .btn {
        margin-bottom: 10px;
        background: $darkOrange;
        width: 150px;
        outline: none;
        border: none;
        height: 35px;
      }
    }
  }
}
