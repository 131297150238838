@import "../../Constants/color.scss";

.maintenance {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
}
