@import "../../Constants/color.scss";
/* -------------------------------- 

Primary style

-------------------------------- */
.route {
  .top {
    background-color: $gray;
    padding: 5px;
  }

  .-m-5 {
    margin-right: 7px;
  }

  .active-btn {
    background-color: $darkOrange;
  }

  section h2 {
    width: 100%;
    color: $secondaryColor;
    font-size: 2rem;
    font-weight: 700;
    text-align: left;
  }
  .cd-breadcrumb li {
    font-size: 14px;
    margin: 0px 8px 0px 0px !important;
  }
  .row {
    display: flex;
    justify-content: space-between;
  }
  .toggle1 {
    display: flex;
    align-items: center;
    .route-access {
      position: relative;
      cursor: pointer;
      margin-left: 10px;
      font-size: 14px;
      color: $white;
      .route-groups {
        visibility: hidden;
        font-size: 13px;
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 11;
        background: rgba(0, 0, 0, 0.7294117647058823);
        padding: 8px;
        border-radius: 8px;
        left: -8px;
        top: 22px;
      }
      .triangle-up {
        visibility: hidden;
        width: 0;
        height: 0;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;
        border-bottom: 41px solid rgba(0, 0, 0, 0.7294117647058823);
        position: absolute;
        top: 15px;
        left: -14px;
      }
    }
    .route-access:hover {
      .route-groups {
        visibility: visible;
      }
      .triangle-up {
        visibility: visible;
      }
    }
    .route-main {
      border-bottom: 1px solid;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
    .international {
      border-bottom: none;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .route-main-disable {
      cursor: not-allowed;
    }
    .route-access-disable {
      pointer-events: none;
    }
    .breadcrumb-disable {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .toggle2 {
    float: right;
    display: flex;
    justify-content: flex-end;
  }

  /* -------------------------------- 
  
  Basic Style
  
  -------------------------------- */

  .MuiToolbar-regular {
    display: none;
  }
  .cd-breadcrumb {
    display: flex;
    margin: 0px;
  }
  .cd-breadcrumb:after,
  .cd-multi-steps:after {
    content: "";
    display: table;
    clear: both;
  }
  .cd-breadcrumb li,
  .cd-multi-steps li {
    display: inline-block;
    float: left;
    margin: 0.5em 0;
    cursor: pointer;
    min-width: auto;
    max-width: 110px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.posContri {
  .modal-dialog {
    width: 900px;
  }
  #ModalHeader {
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }
  .modal-body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .posContriBodyTop{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .headingPOS {
        font-size: 18px !important;
        margin: 0px;
      }
      select.pos-route-dropdown {
        width: max-content;
        margin: 0px;
      }
    }
  }
}

@media (max-height: 620px) {
  .cd-breadcrumb li,
  .cd-multi-steps li {
    max-width: 110px !important;
  }
}
